<template>
  <page-view class="logistics" :up-class="'logistics'">

    <div class="banner">
      <el-carousel trigger="click" :interval="2000" arrow="always" ref="carouselRef">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img v-lazy="item.image" width="100%" height="100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="texts">
      <div>多元化物流运输优选</div>
      <div>
        运输过程智能管理，实时可视。服务可靠，全国通达。<br/>
        依据不同道具自有特性，量身定制道具包装方式及运输服务。<br/>
        平台直接对接物流公司，有价格优势。按要求时间送达，省事省心。
      </div>
    </div>

    <div class="twobanner">
      <el-carousel style="height: 100% !important;" trigger="click" arrow="always" :interval="2000" ref="carouselRef">
        <el-carousel-item style="height: 100% !important;" v-for="(item, index) in twobannerList" :key="index">
          <img v-lazy="item.image" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="product-show">
      <div class="tltle">物流公司展示</div>

      <div class="look-more">
        <div class="btn" @click="$router.push({ name: 'DesignComAll', params: { companyType: 8 } })">查看更多</div>
      </div>

      <div class="product-show-content">
        <div class="product-item" v-for="item in theComList" :key="item.id" @click="toComIndex(item)">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-name">{{ item.mainProduct }}</div>
          <div><img :src="item.cmsArticle2[0]?.image || item.cmsArticle1[0]?.image || item.cmsArticle4[0]?.image || item.logo" :alt="item?.name" height="100%" width="100%" /></div>
        </div>
      </div>
      <div style="text-align: center;color: #a5a3a3;">百川智慧商展产业数字化平台</div>
    </div>

    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Banner from "@/components/provideMater/Banner.vue";
export default {
  name: "Logistics",
  components: { Banner },
  data() {
    return {
      bannerList: [],
      twobannerList: [],
      comList: [],
    };
  },
  computed: {
    theComList() {
      return this.comList.filter((item) => item); 
    },
  },
  created() {
    this.selectSnLogistics();
    this.getImgs()
    this.selectSn();
  },
  methods: {
    ...mapActions(["setTheArticle", "setTheArticleList"]),
    async selectSnLogistics() {
      let result = await this.$api.getBanner('PT00015');
      this.bannerList = result.itemList;
    },
    async getImgs() {
      let result = await this.$api.getBanner('PT00722');
      this.twobannerList = result.itemList;
    },

    toComIndex(item) {
      if (item) {
        let syscompanyid = item.syscompanyid;
        const openRoute = this.$router.resolve({
          name: "ComIndex",
          params: { syscompanyid },
        });
        window.open(openRoute.href, "_blank");
      }
    },
    // 获取物流公司
    async selectSn() {
      this.comList = Array(16);
      let result = await this.$api.selectSn({
        bc_company_type: 8,
        current: 1,
        size: 8,
        s3:0//是优质企业
      });
      result.data.forEach((item, index) => {
        this.$set(this.comList, index, item);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.logistics {
  background-color: #f5f5f5;

  .banner {
    :deep(.el-carousel) {
      .el-carousel__container {
        height: 21vw;

        .el-carousel__arrow {
          font-size: 28px;
          @include flexbox(row, center, center);
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .texts {
    font-size: 2.6vw;
    color: rgb(41, 41, 41);
    font-weight: 600;
    width: 80%;
    margin: auto;
    padding: 7% 0%;

    >div:nth-child(2) {
      margin-top: 2%;
      font-weight: 400;
      font-size: 1.5vw;
      line-height: 2;
    }
  }

  .twobanner {
    height: 30vw;

    :deep(.el-carousel__container) {
      height: 100% !important;
    }
  }

  .product-show {
    padding: 5% 0% 4%;
    background-color: #F6F6F6;
    overflow: hidden;

    .tltle {
      font-size: max(16px,3.5vw);
      text-align: center;
    }

    .look-more {
      margin: 0 auto;
      color: #4C9C51;
      text-align: center;

      .btn {
        font-size: 12px;
        padding: 10px 0px;
        cursor: pointer;
      }
    }

    .product-show-content {
      width: 90%;
      margin: 3% auto;
      font-size: 1vw;
      color: #666666;
      white-space: nowrap;
      animation: liner 20s infinite linear;

      @keyframes liner {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-90%);
        }
      }

      &:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
      }

      .product-item {
        background: #ffffff;
        width: max(150px,30%);
        height: max(100px,20vw);
        padding-top: 2%;
        border-radius: 1vw;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        margin-right: 3%;
        text-align: center;
        cursor: pointer;
        .item-name{
          width: 90%;
          margin: auto;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        >div {
          padding: 1% 0%;
        }

        >div:nth-child(1) {
          font-weight: bold;
        }

        >div:nth-child(2) {
          padding-bottom: 6%;
          font-size: 0.9vw;
        }

        >div:nth-child(3) {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
</style>